import _ from 'lodash';
import { zipMap } from "../utils/Utils";

const Networks = {
  __deprecated__ETHEREUM: 'ethereum',
  THETA_TESTNET: 'testnet',
  THETA_TESTNET_AMBER: 'testnet_amber',
  THETA_TESTNET_SAPPHIRE: 'testnet_sapphire',
  THETA_MAINNET: 'mainnet',
  THETA_PRIVATENET: 'privatenet',
};

export const NetworksWithDescriptions = [
  {
    id: Networks.THETA_MAINNET,
    name: "Mainnet",
    description: "THETA mainnet (Default)",
    faucetId: "mainnet"
  },
  {
    id: Networks.THETA_TESTNET,
    name: "Testnet",
    description: "THETA testnet",
    faucetId: "testnet"
  },
  {
    id: Networks.THETA_TESTNET_AMBER,
    name: "Testnet_Amber",
    description: "THETA testnet for elite edge nodes (Apr 2021)"
  },
  {
    id: Networks.THETA_TESTNET_SAPPHIRE,
    name: "Testnet (Sapphire)",
    description: "THETA testnet for guardian nodes (Feb 2020)",
    faucetId: "sapphire"
  },
  {
    id: Networks.THETA_PRIVATENET,
    name: "Smart Contracts Sandbox",
    description: "THETA testnet for Smart Contracts (ALPHA)",
    faucetId: "smart_contract"
  }
];

export const NetworksById = zipMap(NetworksWithDescriptions.map(({ id }) => id), NetworksWithDescriptions);

export const NetworkExplorerUrls = {
  [Networks.THETA_MAINNET]: 'https://explorer.thetatoken.org',
  [Networks.THETA_TESTNET]: 'https://beta-explorer.thetatoken.org',
  [Networks.THETA_PRIVATENET]: 'https://localhost:3000'
};

export function isEthereumNetwork(network) {
  return (network === Networks.__deprecated__ETHEREUM);
}

export function isThetaNetwork(network) {
  return (network !== Networks.__deprecated__ETHEREUM);
}

export function canEdgeNodeStake(network) {
  return true;
}

export function canGuardianNodeStake(network) {
  return true;
}

export function canViewSmartContracts(network) {
  return true;
}

export function getNetworkName(networkId) {
  return _.get(NetworksById, [networkId, 'name']);
}

export function getNetworkFaucetId(networkId) {
  return _.get(NetworksById, [networkId, 'faucetId']);
}

export const NetworkIds = {
  [Networks.THETA_MAINNET]: '0x169',
  [Networks.THETA_TESTNET]: '0x16d',
  [Networks.THETA_PRIVATENET]: '0x6'
}

export const NetworkRPCUrls = {
  [Networks.THETA_MAINNET]: 'https://eth-rpc-api.thetatoken.org/',
  [Networks.THETA_TESTNET]: 'https://eth-rpc-api-testnet.thetatoken.org/rpc',
  [Networks.THETA_PRIVATENET]: 'http://localhost:18888/rpc'
}

export const NetworkBlockPeriod = {
  [Networks.THETA_MAINNET]: 6,
  [Networks.THETA_TESTNET]: 6,
  [Networks.THETA_PRIVATENET]: 1
}

export default Networks;
