import Modal from './Modal';
import './ConnectWalletModal.scss';
import { connect } from 'react-redux';
import useIsMountedRef from '../hooks/useIsMountedRef';
import { store } from '../state';
import { setAddress, setIsConnecting, setHasError } from '../state/actions/Metamask';
import Spinner from './Spinner';

const ConnectWalletModal = props => {
  const { provider, isConnecting, hasError } = props;
  const isMountedRef = useIsMountedRef();

  const fetchAccounts = async () => {
    store.dispatch(setIsConnecting(true));
    try {
      const accounts = await provider.request({
        method: 'eth_requestAccounts'
      })
      if (!isMountedRef.current) return;
      store.dispatch(setAddress(accounts[0]));
      props.onClose();
    } catch (e) {
      store.dispatch(setHasError(true));
    }
  }

  const handleClose = () => {
    props.onClose();
    store.dispatch(setIsConnecting(false));
  }

  return <Modal className="connect-wallet-modal" onClose={handleClose}>
    <div className="modal-title">Connect a wallet</div>
    <div className="modal">
      <div className="connect-wallet-modal__card">
        By connecting a wallet, you agree to Theta Labs’
        <a href='https://www.thetatoken.org/terms' target="_blank" rel="noopener noreferrer">Terms of Service</a>
        and acknowledge that you have read and understand the Theta
        <a href="/">Protocol Disclaimer</a>.
      </div>
      {isConnecting && <>
        {hasError ? <div className='connect-wallet-modal__error'>
          <div className='connect-wallet-modal__error--title'>Error connecting</div>
          <div className='button' onClick={fetchAccounts}>Try again</div>
        </div> : <div className='connect-wallet-modal__init'>
          <Spinner className='xs' />
          <div className='connect-wallet-modal__wallet--title'>Initializing...</div>
        </div>}
        <div className='connect-wallet-modal__info'>
          <div>
            <div className='connect-wallet-modal__info--title'>MetaMask</div>
            <div className='connect-wallet-modal__info--content'>Easy-to-use browser extension.</div>
          </div>
          <div className='connect-wallet-modal__wallet--icon metamask'></div>
        </div>
      </>}
      {!isConnecting && (provider ? <div className='connect-wallet-modal__wallet' onClick={fetchAccounts}>
        <div className='connect-wallet-modal__wallet--title'>MetaMask</div>
        <div className='connect-wallet-modal__wallet--icon metamask'></div>
      </div> : <a className='connect-wallet-modal__wallet' href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
        <div className='connect-wallet-modal__wallet--title'>Install MetaMask</div>
        <div className='connect-wallet-modal__wallet--icon metamask'></div>
      </a>)}
    </div>
  </Modal>
}

const mapStateToProps = state => ({
  provider: state.metamask.provider,
  isInstalled: state.metamask.isInstalled,
  hasError: state.metamask.hasError,
  isConnecting: state.metamask.isConnecting,
  isFetchingBalances: state.metamask.isFetchingBalances,
});

export default connect(mapStateToProps)(ConnectWalletModal);