import { connect } from 'react-redux';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Config from '../Config';
import DashboardTabs from './DashboardTabs';
import Spinner from '../components/Spinner';
import './Dashboard.scss';
import useLocalStorage from '../hooks/useLocalStorage';

const Dashboard = props => {
  const { provider, address } = props;

  const [fileList, setFileList] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [keys, setKeys] = useLocalStorage("keys", [])
  const [key, setKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAuth = async () => {
    if (!provider) {
      // should not reach here.
      throw Error('wallet not installed');
    }

    const timestamp = Date.now().toString();
    const msg = 'Theta EdgeStore Call ' + timestamp;

    const sig = await provider.request({
      method: 'personal_sign',
      params: [msg, address],
    });

    return timestamp + "." + address + "." + sig;
  }

  const handleSubmit = async () => {
    if (!fileList || Object.keys(fileList).length === 0) {
      return;
    }
    if (error) setError(false);
    setIsLoading(true);
    let auth;
    try {
      auth = await getAuth();
    } catch (_) {
      console.error("Failed to get user's signature");
      setError("Failed to get user's signature")
      setIsLoading(false);
      return;
    }
    try {
      console.log('tabIndex:', tabIndex);
      console.log('fileList:', fileList);
      let formData = new FormData();
      if (tabIndex === 0) {
        formData.append("file", fileList[0]);
      } else if (tabIndex === 1) {
        for (let i = 0; i < Object.keys(fileList).length; i++) {
          console.log('i:', fileList[i]);
          formData.append("directory", fileList[i])
        }
      }
      console.log('formData:', formData);
      let url = `${Config.baseWriteAPIUrl}/api/v2/data`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'x-theta-edgestore-auth': auth
        },
        body: formData
      });
      const respJson = await response.json();
      const key = respJson.key;
      setKey(key);
      if (keys.includes(key)) return;
      setKeys(keys.concat(key))
    } catch (error) {
      console.log('error:', error.message)
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return <div className='dashboard-wrap'>
    <div className='dashboard'>
      <div className='dashboard__header'>
        <Link to="/" className="theta-logo"></Link>
      </div>
      <div className='dashboard__title'>Theta Edge Store</div>
      <div className='dashboard__subtitle'>Persistence layer for the Theta Universe.</div>
      <DashboardTabs fileList={fileList} setFileList={setFileList} tabIndex={tabIndex} setTabIndex={setTabIndex} setKey={setKey} setError={setError} />
      {tabIndex !== 2 && (key ? <div className='dashboard__download'>
        <div className='dashboard__download--title'>DOWNLOAD LINK</div>
        <a className='dashboard__download--link' href={`${Config.baseReadAPIUrl}/api/v2/data/${key}`} target={'_blank'} rel="noreferrer">
          {Config.baseReadAPIUrl}/api/v2/data/{key}
        </a>
      </div> : <div className={`dashboard__submit`}>
        {error && <div className='dashboard__submit--error'>{error}</div>}
        {isLoading ? <div className={`dashboard__submit--button disabled`}>
          <Spinner className='xs' />Uploading...
        </div> : <div onClick={handleSubmit}
          className={`dashboard__submit--button ${fileList && Object.keys(fileList).length ? "" : "disabled"}`}>
          Upload
        </div>}
      </div>)}
    </div>
  </div>
}
const mapStateToProps = state => ({
  networkId: state.metamask.networkId,
  provider: state.metamask.provider,
  address: state.metamask.address,
});

export default connect(mapStateToProps)(Dashboard);