import * as actionTypes from "../types/Metamask";
import { BigNumber } from 'bignumber.js';
import { WEI } from '../../constants/Utils';

const INITIAL_STATE = {
  networkId: '',
  provider: null,

  hasError: false,
  isConnecting: false,
  isConnected: false,
  isFetchingBalance: false,

  address: null,
  balance: 0
};

export const metamaskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_NETWORKID: {
      return Object.assign({}, state, {
        networkId: action.networkId
      });
    }
    case actionTypes.SET_PROVIDER: {
      return Object.assign({}, state, {
        provider: action.provider
      })
    }
    case actionTypes.SET_ADDRESS: {
      return Object.assign({}, state, {
        address: action.address,
        isConnecting: false,
        hasError: false
      })
    }
    case actionTypes.SET_ISCONNECTING: {
      return Object.assign({}, state, {
        isConnecting: action.isConnecting,
        hasError: false
      })
    }
    case actionTypes.SET_HASERROR: {
      return Object.assign({}, state, {
        hasError: action.hasError
      })
    }
    case actionTypes.FETCH_BALANCE_START: {
      return Object.assign({}, state, {
        isFetchingBalance: true
      })
    }
    case actionTypes.FETCH_BALANCE_END: {
      return Object.assign({}, state, {
        isFetchingBalance: false
      })
    }
    case actionTypes.FETCH_BALANCE_SUCCESS: {
      const balance = new BigNumber(action.response.body).dividedBy(WEI).toFixed(2);
      return Object.assign({}, state, {
        isFetchingBalance: true,
        balance
      })
    }
    case actionTypes.SET_BALANCE: {
      return Object.assign({}, state, {
        balance: action.balance,
        isFetchingBalance: false
      })
    }
    case actionTypes.SET_ISFETCHINGBALANCE: {
      return Object.assign({}, state, {
        balance: 0,
        isFetchingBalance: true
      })
    }
    default: {
      return state
    }
  }
};
