import {
  SET_NETWORKID, SET_PROVIDER, SET_ADDRESS, SET_ISCONNECTING,
  SET_HASERROR
  // SET_BALANCE, SET_ISFETCHINGBALANCE,
} from "../types/Metamask";

export function setNetworkId(networkId) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_NETWORKID,
      networkId: networkId
    }
    );
  };
}

export function setProvider(provider) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_PROVIDER,
      provider: provider
    })
  }
}

export function setAddress(address) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_ADDRESS,
      address: address,
    })
  }
}

export function setIsConnecting(isConnecting) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_ISCONNECTING,
      isConnecting: isConnecting,
    })
  }
}

export function setHasError(hasError) {
  return async function (dispatch, getState) {
    dispatch({
      type: SET_HASERROR,
      hasError: hasError
    })
  }
}
