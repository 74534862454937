const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon"
];

export function validImageType(file) {
  return fileTypes.includes(file.type);
}

export function validVideoType(file) {
  console.log(file.type);
  return file.type?.includes("video");
}