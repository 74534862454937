import Networks, { NetworkIds, NetworkExplorerUrls, NetworkRPCUrls } from './constants/Networks'

const network = process.env.REACT_APP_NETWORK || Networks.THETA_PRIVATENET;
const baseReadAPIUrl = process.env.REACT_APP_API_URL || "http://localhost:18080";
const baseWriteAPIUrl = process.env.REACT_APP_WRITE_API_URL || process.env.REACT_APP_API_URL || "http://localhost:18080";

const config = {
  defaultThetaChainID: network,
  mainNetChainId: NetworkIds[network],
  explorerUrl: NetworkExplorerUrls[network],
  rpcUrl: NetworkRPCUrls[network],

  baseReadAPIUrl,
  baseWriteAPIUrl
};

export default config;
