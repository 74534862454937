import BigNumber from 'bignumber.js';
import moment from 'moment';

/**
 * Returns a new object with vals mapped to keys
 * @param {Array} keys
 * @param {Array} vals
 * @return {Object}
 */
export function zipMap(keys, vals) {
  return Object.assign({}, ...keys.map((key, index) => ({ [key]: vals[index] })));
}

export function truncateAddress(address, startLength = 6, endLength = 4) {
  if (typeof address !== 'string') return '';
  return address.substring(0, startLength) + '...' + address.substring(address.length - endLength);
}

export function copyToClipboard(str) {
  //https://gist.githubusercontent.com/Chalarangelo/4ff1e8c0ec03d9294628efbae49216db/raw/cbd2d8877d4c5f2678ae1e6bb7cb903205e5eacc/copyToClipboard.js

  const el = document.createElement('textarea');  // Create a <textarea> element
  el.value = str;                                 // Set its value to the string that you want copied
  el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px';                      // Move outside the screen to make it invisible
  document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0)     // Store selection if found
      : false;                                    // Mark as false to know no selection existed before
  el.select();                                    // Select the <textarea> content
  document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el);                  // Remove the <textarea> element
  if (selected) {                                 // If a selection existed before copying
    document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
    document.getSelection().addRange(selected);   // Restore the original selection
  }
}


export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getHex(str) {
  const buffer = Buffer.from(str, 'base64');
  const bufString = buffer.toString('hex');
  return '0x' + bufString;
}

export function formatQuantity(amount, decimals, length = 4) {
  decimals = decimals || 0;
  let wei = new BigNumber(10).exponentiatedBy(decimals);
  return new BigNumber(amount).dividedBy(wei).decimalPlaces(length).toFormat({
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  });
}

export function date(timestamp) {
  return moment(parseInt(timestamp)).format("MM/DD/YY hh:mmA");
}

export function getAmount(weiAmount, decimals) {
  decimals = decimals || 0;
  let wei = new BigNumber(10).exponentiatedBy(decimals);
  return new BigNumber(weiAmount).dividedBy(wei).toFixed();
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}