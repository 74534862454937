import { SHOW_MODAL, HIDE_MODAL } from "../types/Modal";

export function showModal(modalType) {
  return {
    type: SHOW_MODAL,
    modalType: modalType
  }
}

export function hideModal() {
  return {
    type: HIDE_MODAL
  }
}