import * as actionTypes from "../types/Modal";

const INITIAL_STATE = {
  type: ""
};

export const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL: {
      return Object.assign({}, state, {
        type: action.modalType
      });
    }
    case actionTypes.HIDE_MODAL: {
      return Object.assign({}, state, {
        type: ""
      });
    }

    default: {
      return state
    }
  }
};