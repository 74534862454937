import cx from 'classnames';
import './Modal.scss';

const Modal = props => {
  return <div className="modal-container" onClick={props.onClose}>
    <div className={cx("modal-content", props.className)} onClick={e => e.stopPropagation()}>
      <div className="modal-close" onClick={props.onClose}></div>
      {props.children}
    </div>
  </div>
}

export default Modal;