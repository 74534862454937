import React from 'react';
import './App.scss';
import NavBar from './components/NavBar';

const App = (props) => {
  return (
    <React.StrictMode>
      <div id="app-container">
        <NavBar />
        {props.children}
      </div>
    </React.StrictMode>
  );
}

export default App;