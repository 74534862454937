import Modal from './Modal';
import './ErrorModal.scss';

const ActionModal = props => {
  return <Modal className="error-modal" onClose={props.onClose}>
    <div className="modal-title">{props.title}</div>
    <div className="modal">
      {props.content}
    </div>
  </Modal>
}

export default ActionModal;