const name = 'METAMASK';

export const SET_NETWORKID = `${name}/SET_NETWORKID`;
export const SET_PROVIDER = `${name}/SET_PROVIDER`;
export const SET_ADDRESS = `${name}/SET_ADDRESS`;
export const SET_ISCONNECTING = `${name}/SET_ISCONNECTING`;
export const SET_HASERROR = `${name}/SET_HASERROR`;
export const SET_BALANCE = `${name}/SET_BALANCE`;
export const SET_ISFETCHINGBALANCE = `${name}/SET_ISFETCHINGBALANCE`;

export const FETCH_BALANCE = `${name}/FETCH_BALANCE`;
export const FETCH_BALANCE_START = `${FETCH_BALANCE}/START`;
export const FETCH_BALANCE_END = `${FETCH_BALANCE}/END`;
export const FETCH_BALANCE_FAILURE = `${FETCH_BALANCE}/FAILURE`;
export const FETCH_BALANCE_SUCCESS = `${FETCH_BALANCE}/SUCCESS`;