import { connect, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { createRef, useState } from 'react';
import { validImageType, validVideoType } from '../helper';
import './DashboardTabs.scss';
import { store } from "../state";
import Config from '../Config';
import { showModal, hideModal } from '../state/actions/Modal';
import useLocalStorage from '../hooks/useLocalStorage';
import {
  generateVideoThumbnails
} from "@rajesh896/video-thumbnails-generator";

const DashboardTabs = props => {
  const { fileList, setFileList, tabIndex, setTabIndex, setKey, networkId, provider, address, setError } = props;
  const isCorrectNetwork = Config.mainNetChainId === networkId;

  const handleSelect = (i, _, e) => {
    if (i === 2 && checkAndOpenModel()) {
      e.preventDefault();
      return;
    }
    if (i === tabIndex) return;
    setFileList({});
    setKey("");
    setError();
    setTabIndex(i);
  }

  const checkAndOpenModel = () => {
    if (!provider) {
      store.dispatch(showModal('connect'));
      return true;
    }
    if (!isCorrectNetwork) {
      store.dispatch(showModal('networkErr'))
      return true;
    }
    if (address == null) {
      store.dispatch(showModal('connect'));
      return true;
    }
    return false;
  }

  return <Tabs className="theta-tabs" selectedIndex={tabIndex} onSelect={handleSelect} >
    <TabList>
      <Tab>SELECT A FILE</Tab>
      <Tab>SELECT A DIRECTORY</Tab>
      <Tab>RECENT UPLOADS</Tab>
    </TabList>

    <TabPanel>
      <Uploader type='file' fileList={fileList} setFileList={setFileList} checkAndOpenModel={checkAndOpenModel}
        setKey={setKey} setError={setError} />
    </TabPanel>
    <TabPanel>
      <Uploader type='directory' fileList={fileList} setFileList={setFileList} checkAndOpenModel={checkAndOpenModel}
        setKey={setKey} setError={setError} />
    </TabPanel>
    <TabPanel>
      <RecentUploaded />
    </TabPanel>
  </Tabs>
}
const mapStateToProps = state => ({
  networkId: state.metamask.networkId,
  provider: state.metamask.provider,
  address: state.metamask.address,
  modalType: state.modal.type,
});

const RecentUploaded = (props) => {
  const [keys, _] = useLocalStorage('keys', []);
  console.log('keys:', keys);
  return <div className='uploaded'>
    {keys.length === 0 ? <div>
      No Recent Uploads
    </div> : keys.map((_, i) => {
      let key = keys[keys.length - i - 1];
      let url = `${Config.baseReadAPIUrl}/api/v2/data/${key}`;
      return <a className='uploaded__link' key={i}
        href={url}
        target={'_blank'} rel="noreferrer">
        {key}
      </a>
    })}
  </div>
}

const Uploader = (props) => {
  const { type, fileList, setFileList, checkAndOpenModel, setKey, setError } = props;
  const inputRef = createRef();
  const [coverImages, setCoverImages] = useState({});

  const handleOnChange = async () => {
    const fileList = inputRef.current.files;
    console.log('current files: ', fileList);
    console.log('onChange');
    if (fileList.length !== 0) {
      if (setKey) setKey("");
      setError();
    } else {
      return;
    }
    setFileList(inputRef.current.files);

    const cImage = {};
    // Object.keys(fileList).forEach(async key => {
    //   let file = fileList[key];
    //   console.log('file:', file);
    //   if (validVideoType(file)) {
    //     let image = await generateVideoThumbnails(file);
    //     console.log('image:', image[0]);
    //     cImage[key] = image[0];
    //   }
    // })
    for (let i = 0; i < Object.keys(fileList).length; i++) {
      let file = fileList[i];
      console.log('file:', file);
      if (validVideoType(file)) {
        let image = await generateVideoThumbnails(file);
        console.log('image:', image[0]);
        cImage[i] = image[0];
      }
    }
    setCoverImages(cImage);
    // setFiles(Object.keys(fileList).map(k => fileList[k]))
    // inputRef.current.files.forEach(f => { console.log(f); return f })
    // setFiles(inputRef.current.files);
  }

  const handleOnClick = (e) => {
    if (checkAndOpenModel()) {
      e.preventDefault();
    }
  }

  console.log('cover image:', coverImages);

  return <div className='uploader'>
    {type === 'file' ? <>
      <label className='uploader__selector'>
        <input type="file" ref={inputRef} onChange={handleOnChange} onClick={handleOnClick} />
        Choose file
      </label>
    </> : <>
      <label className='uploader__selector'>
        <input type="file" directory="" webkitdirectory="" ref={inputRef} onChange={handleOnChange} onClick={handleOnClick} />
        Choose directory
      </label>
    </>}
    <div className='uploader__preview'>
      {(Object.keys(fileList).length) ? <div className='uploader__file-wrap'>
        {Object.keys(fileList).map((k, i) => {
          const file = fileList[k];
          const isImage = validImageType(file);
          const isVideo = validVideoType(file);
          console.log('isImage:', isImage, file);
          console.log('coverImage:', coverImages, k, coverImages[k]);
          const styleObj = isImage ? { backgroundImage: `url(${URL.createObjectURL(file)})` } : isVideo ?
            { backgroundImage: `url(${coverImages[k]})` } : {};
          return <div className='uploader__file' key={i} >
            <div className='uploader__file--icon' style={styleObj} ></div>
            <div className='uploader__file--name'>{file.name}</div>
          </div>
        })}
      </div> : <div className='uploader__preview--empty'>No {type} selected</div>}
    </div>
  </div >
}

export default connect(mapStateToProps)(DashboardTabs);